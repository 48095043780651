import Cookie from 'js-cookie';

function checkAdobeReady (max) {
  if (window?._satellite) {
    Cookie.set('c_desturl_s', window._satellite.getVar('page.section'), {
      domain: '.newsday.com',
      expires: 1
    });
  } else {
    setTimeout(() => { checkAdobeReady(max); }, 250);
  }
}

export default function Write_Cookies () {
  // cookie for detUrl subscription flow.
  const destUrl = function () {
    Cookie.set('c_desturl', location.href, {
      domain: '.newsday.com',
      expires: 1
    });
    checkAdobeReady(12);
  };

  // drop the gate for subs coming from newsletters
  if (window.location.href.indexOf('utm_term=sub') > -1) {
    Cookie.set('c_brs', 'ndwsl', {
      domain: '.newsday.com',
      expires: new Date(new Date().getTime() + 30 * 60 * 1000)
    });
  }

  // abtasty cookies
  const cookie_vals = ['a', 'b', 'c'];
  const rand2 = Math.floor(Math.random() * 2);

  if (!Cookie.get('c_rand2')) {
    Cookie.set('c_rand2', cookie_vals[rand2], {
      domain: '.newsday.com',
      expires: 30
    });
  }

  /* cookie for a/b/c test meter screens */
  const rand3 = Math.floor(Math.random() * 3);
  if (!Cookie.get('c_rand3')) {
    Cookie.set('c_rand3', cookie_vals[rand3], {
      domain: '.newsday.com',
      expires: 30
    });
  }

  // desturl cookie for paywall
  window.addEventListener('nd_mg2_onPaywallShown', (e) => {
    const buyButton = document.getElementsByClassName('buyDigital');
    const myFunction = function () {
      destUrl();
    };
    for (let i = 0; i < buyButton.length; i += 1) {
      buyButton[i].addEventListener('click', myFunction, false);
    }
  });

  // desturl cookie for toaster listener
  window.addEventListener('toaster_show', (e) => {
    const subToasterSubscribe = document.querySelectorAll('[class*=toaster-container] div.cta');
    Array.from(subToasterSubscribe).forEach((element) => {
      element.addEventListener('click', destUrl);
    });
  });

  // toaster is already in dom when this code runs
  const subToasterSubscribe = document.querySelectorAll('[class*=toaster-container] div.cta');
  Array.from(subToasterSubscribe).forEach((element) => {
    element.addEventListener('click', destUrl);
  });

  // desturl cookie for exit intent pop listener
  window.addEventListener('exitIntent_pop', (e) => {
    const subExitPop = document.querySelectorAll('[class*=popup-container] div.cta');
    Array.from(subExitPop).forEach((element) => {
      element.addEventListener('click', destUrl);
    });
  });

  // desturl cookie for MG2 Subscribe buttons
  const subscribeElements = document.getElementsByClassName('MG2Subscribe');
  Array.from(subscribeElements).forEach((element) => {
    element.addEventListener('click', destUrl);
  });

  // if igmAuth is missing but c_user is there, rewrite igmAuth
  if (!Cookie.get('igmAuth') && Cookie.get('c_user')) {
    window.addEventListener('nd_user_data_populated', () => {
      console.log('Setting igmAuth cookie');
      Cookie.set(
        'igmAuth',
        window.nd_user.info.get('encrypted_customer_registration_id'),
        {
          domain: '.newsday.com',
          expires: 30
        }
      );
    });
  }
}
