import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import ND_User_API from './inc/api';
import ND_User_Data from './inc/user';
import ND_User_Optins from './inc/optins';
import ND_Client_Info from './inc/clientinfo';
import ND_User_Payload from './inc/payload';
import Config from './config';
import MG2 from './inc/mg2';
import HeadR from './jsx/headr';
import Weather from './jsx/weather';
import WeatherIcon from './jsx/weather-icon';
import Account from './jsx/account_panel';
import Analytics from './inc/analytics';
import write_cookies from './inc/write_cookies';
import auto_login from './inc/auto_login';
import { getUserData, getDataFromStorage } from './inc/getUserData';
import liveramp from './inc/live_ramp';

class ND_User {
  cookie = Cookies;
  items_ready = {
    object: false,
    mg2: false,
    client_info: false
  };

  url = { ...location, qs: new URLSearchParams(location.search) };

  constructor () {
    Object.assign(this, {
      api: new ND_User_API(),
      MG2: new MG2(),
      analytics: new Analytics(this),
      getDataFromStorage: getDataFromStorage.bind(this),
      getUserData: getUserData.bind(this)
    });

    Object.assign(this, {
      info: new ND_User_Data('', this.api),
      opt_ins: new ND_User_Optins('optins', this.api),
      client_info: new ND_Client_Info('', this.api),
      interests: new ND_User_Payload('interests', this.api)
    });

    auto_login(this);
    write_cookies();
    if (!/parenturl=/i.test(window.location.search)) {
      liveramp();
    }

    this.listeners();

    // move setup to a 0 delay timeout to allow object instantiation
    setTimeout(() => {
      this.set_up();
    }, 0);
  }

  /**
   * Dependencies check in when they're ready
   * When all are ready, nd_user_ready is emitted
   * @param {String} key
   */
  lift_off (key) {
    this.items_ready[key] = true;

    const items_remaining = Object.keys(this.items_ready)?.filter(
      (k) => !this.items_ready[k]
    ) || [];

    window.dispatchEvent(new CustomEvent(`nd_user_${key}_ready`));

    if (items_remaining.length === 0) {
      window.dispatchEvent(new CustomEvent('nd_user_ready'));
    }
  }

  /**
   * Handles the logic for adopting user data from the cookie or capturing it from the server
   */
  set_up () {
    this.fetchAllowList();

    this.client_info
      .fetch()
      .then(() => {
        this.lift_off('client_info');
      })
      .catch((e) => {
        console.log(e);
      });

    this.setBlockedSite();

    // update instance parameters to work off of cookie data and presence
    Object.assign(this, {
      user_data_storage: window.localStorage.getItem(Config.app.cookie.name),
      is_logged_in: !!Cookies.get('igmRegID')
    });

    if (!this.is_logged_in) {
      this.lift_off('object');
      return true; // short circuit
    }

    // if the user is logged in and the cookie exists, just use the cookie data
    if (this.user_data_storage && this.is_logged_in) {
      console.log('user data found and user logged in. Adopted from storage');
      this.getDataFromStorage();
      this.lift_off('object');
      return true; // short circuit
    }

    // if the user is logged in, but the cookie isn't set, get the cookie
    if (this.is_logged_in) {
      // user is logged in but cprefs isn't here, so get user data
      console.log('User is logged in but no data found in storage');
      this.getUserData()
        .then(() => {
          this.lift_off('object');
        })
        .catch((e) => {
          this.lift_off('object');
          console.log(e);
        });
    }
  }

  listeners () {
    // if the user is not logged in, establish a listener in case that changes during this session
    window.addEventListener('nd_mg2_onLoggedIn', (e) => {
      console.log('MG2 logged in event detected');
      if (!this.is_logged_in) {
        Object.assign(this, { is_logged_in: true });
      }
    });

    // if the user is not logged in, establish a listener in case that changes during this session
    window.addEventListener('nd_mg2_onRun', (e) => {
      /**
       * If the user is logged in, get all of their data
       */
      this.mg2_ready = true;

      if (e.detail.EventData === 'User logout') {
        console.log('MG2 logged out event detected');
        window.dispatchEvent(new CustomEvent('nd_mg2_onLoggedOut', { ...e }));
        Object.assign(this, { is_logged_in: false });
      }
    });

    window.addEventListener('nd_mg2_onInit', (e) => {
      this.lift_off('mg2');
    });

    window.addEventListener('nd_mg2_onLoginSuccess', (e) => {
      setTimeout(() => {
        this.getUserData();
        document.querySelector('body').classList.remove('modal-open');
        document.querySelectorAll('div.modal-scrollable').forEach((div) => {
          div.classList.remove('modal-scrollable');
        });
      }, 250);
    });

    window.addEventListener('nd_mg2_onLoggedOut', (e) => {
      window.location.reload();
    });

    window.addEventListener('nd_user_ready', (e) => {
      console.log('User object is ready');
      this.build_ui();
    });

    window.addEventListener('nd_user_data_populated', (e) => {
      console.log('User data is ready');
      this.build_ui();
    });

    /**
     * Activation settings
     */
    const activated_events = ['onHasAccess', 'onAuthorized'];
    const not_activated_events = [
      'onHasAccessNotEntitled',
      'onHasNoActiveSubscription',
      'onNotAuthorized'
    ];

    activated_events.forEach((ev) => {
      window.addEventListener(`nd_mg2_${ev}`, (e) => {
        Object.assign(this, { is_activated: true });
      });
    });

    not_activated_events.forEach((ev) => {
      window.addEventListener(`nd_mg2_${ev}`, (e) => {
        Object.assign(this, { is_activated: false });
      });
    });
  }

  build_ui () {
    const headR_container = document.querySelector('.headR');
    const weather_containers = document.querySelectorAll('section.weather');
    const weather_icon_containers = document.querySelectorAll(
      '#trending-bar section.weather'
    );
    const account_container = document.querySelector('#account');

    ReactDOM.render(<HeadR user_obj={this} />, headR_container);

    ReactDOM.render(<Account user_obj={this} />, account_container);

    weather_containers.forEach((c) => {
      if (!c.closest('#trending-bar') && !c.closest('.hyper-local-weather')) {
        ReactDOM.render(<Weather user_obj={this} />, c);
      }
    });

    weather_icon_containers.forEach((c) => {
      ReactDOM.render(<WeatherIcon user_obj={this} />, c);
    });

    if (this.isInApp()) {
      document.querySelector('body').classList.add('app');
    }

    const promoContainer = document.querySelector('.promoContainer');

    if (this.isLoggedIn()) {
      if (promoContainer) {
        promoContainer.classList.remove('active');
      }
    } else if (promoContainer) {
      promoContainer.classList.add('active');
    }
  }

  /**
   * Determines if the user is logged in using app check and ConneXt
   * @returns {boolean}
   */
  isLoggedIn () {
    return this.is_logged_in || this.isInAppLoggedIn();
  }

  isInNwslSub () {
    return (
      window.location.href.indexOf('utm_term=sub') > -1 ||
      Cookies.get('c_brs') === 'ndwsl'
    );
  }

  isInApp () {
    return !!this.isInAppLoggedIn() || !!this.isInAppNotLoggedIn();
  }

  isInAppLoggedIn () {
    return navigator.userAgent.indexOf('newsdayAccessTrue') > -1;
  }

  isInAppNotLoggedIn () {
    return navigator.userAgent.indexOf('newsdayAccessFalse') > -1;
  }

  logout () {
    Cookies.remove('igmAuth', { domain: '.newsday.com' });
    Cookies.remove('igmRegID', { domain: '.newsday.com' });
    Cookies.remove('igmContent', { domain: '.newsday.com' });
    Cookies.remove(Config.app.cookie.name, { domain: '.newsday.com' });
    localStorage.removeItem('Cookie_igmContent');
    localStorage.removeItem('Cookie_igmRegID');
    this.info.clear();
    window.dispatchEvent(new CustomEvent('nd_mg2_onLoggedOut'));
  }

  getProductID () {
    if (!window.Connext) {
      throw new Error(
        'Connext is not ready. You must fire this function after nd_mg2_onInit'
      );
    }

    if (
      typeof window.Connext.Storage.GetUserData().DigitalAccess.Subscription !==
        'undefined' &&
      typeof window.Connext.Storage.GetUserData().DigitalAccess.Subscription
        .PaperCode !== 'undefined'
    ) {
      return window.Connext.Storage.GetUserData().DigitalAccess.Subscription
        .PaperCode;
    }
    console.log('Can not find PaperCode from this account.');
    return false;
  }

  isSubscriber () {
    if (!window.Connext) {
      console.log(
        'Connext is not ready. You must fire this function after nd_mg2_onInit. Returning false for now'
      ); /* removelogging:skip */
      return false;
    }

    return window.Connext.Storage.GetUserData().OwnedSubscriptions.length > 0;
  }

  getHostMap () {
    const host_map = {
      local: {
        site: 'https://stage.newsday.com',
        tools: 'https://local.tools.newsday.com'
      },
      stage: {
        site: 'https://stage.newsday.com',
        tools: 'https://stage.tools.newsday.com'
      },
      prod: {
        site: 'https://www.newsday.com',
        tools: 'https://tools.newsday.com'
      }
    };

    let env = 'prod';

    if (
      location.hostname.indexOf('stage') > -1 ||
      location.pathname.indexOf('/stage/') > -1
    ) {
      env = 'stage';
    }

    if (location.hostname.indexOf('local') > -1) {
      env = 'local';
    }
    return host_map[env];
  }

  // fetch allowlist on non-eedition pages, the list is used for analytics and video gating
  fetchAllowList () {
    const pl = {};
    if (
      window.location.href.indexOf('paper.newsday.com') === -1 &&
      !this.allowListChecked &&
      !this.isLoggedIn()
    ) {
      fetch(`${this.getHostMap().site}/json/?view=navigation&device=iphone-4`)
        .then((r) => r.json())
        .then((d) => {
          // add the response to the pl object
          Object.assign(pl, d);
          return fetch(
            `${this.getHostMap().tools}/ip-allow-list/api/v1/check`
          ).then((r) => r.json());
        })
        .then((d) => {
          Object.assign(pl, { allow_list: d.data });
          window.allowListInfo = pl;
        });
    }
  }

  /**
   * Determines if the user should have access to the edition
   *
   * This method returns a promise because of it's async nature. It first checks the app feeds to make sure we haven't dropped the paywall across the site.
   * The promise passes a boolean reflective of the site's status as well as the users. Invokers just need to check for a 'false' response to send them to login
   * If 'true' is there, let them in.
   * @returns Promise
   */
  hasEEdition () {
    const pl = {};
    return fetch(
      `${this.getHostMap().site}/json/?view=navigation&device=iphone-4`
    )
      .then((r) => r.json())
      .then((d) => {
        // add the response to the pl object
        Object.assign(pl, d);
        return fetch(
          `${this.getHostMap().tools}/ip-allow-list/api/v1/check`
        ).then((r) => r.json());
      })
      .then((d) => {
        Object.assign(pl, { allow_list: d.data });
        // if this is a school or internal on the allowlist
        let permit =
          pl.allow_list.allow &&
          ['internal', 'school', 'library'].indexOf(
            pl.allow_list.entity?.type.toLowerCase()
          ) > -1;

        if (permit) {
          this.pl = pl.allow_list.entity;
        }

        console.log(pl);

        // if the site is open, eedition is open
        if (pl.paywall.setBlock === 'open') {
          permit = true;
        }

        // if the user is logged in and has e-edition access
        if (this.isLoggedIn() && Cookies.get('c_epaper') === 'true') {
          permit = true;
        }

        // if naviga isn't starting
        if (Cookies.get('c_nvginit') === 'false') {
          permit = true;
        }

        // if the user is logged in to inside newsday
        if (Cookies.get('insidend_auth')) {
          permit = true;
        }

        // if the user has a daypass
        if (Cookies.get('c_edayselect') === 'true') {
          permit = true;
        }

        return permit;
      });
  }

  setBlockedSite () {
    const setBlockedSite = JSON.parse(
      document.getElementById('pageData').getAttribute('data-omni')
    ).setblockedsite;
    if (!!setBlockedSite && setBlockedSite === 'open') {
      Cookies.set('c_edayselect', 'true', 1);
      if (
        window.location.href.indexOf('newsday.com/services/login') > -1 &&
        document.referrer === 'https://paper.newsday.com/'
      ) {
        window.location = 'https://paper.newsday.com/stage';
      }
    } else {
      Cookies.remove('c_edayselect');
    }
  }
}

window.ND_User = ND_User;
window.nd_user = new ND_User();
