import dayjs from './dayjs';

const expireDays = 5;
const key_suffix = '20210507a';

const NDLocalStorage = {
  /**
   * Checks local storage for data.
   *
   * Only returns data it the item in local storage exists and is not expired
   * @param {String} key
   * @returns
   */
  getItem (key) {
    if (!localStorage.getItem(`${key}_${key_suffix}`)) {
      console.log(`${key}_${key_suffix} not found`);
      return false;
    }

    let { data, expires, id } = JSON.parse(
      localStorage.getItem(`${key}_${key_suffix}`)
    );

    const now = dayjs.utc();
    expires = dayjs(expires);

    if (window.nd_user.info.get('_id') !== id) {
      console.log(`${key}_${key_suffix} is for a different user`);
      return false;
    }

    if (now.isSameOrAfter(expires)) {
      console.log(`${key}_${key_suffix} expired`);
      console.log(now);
      console.log(expires);
      return false;
    }

    return data;
  },

  /**
   * Stores an object in local storage
   *
   * An expiration time is set for all items stored through this method
   * @param {string} key
   * @param {mixed} data
   */
  setItem (key, data) {
    const payload = {
      data,
      expires: dayjs.utc().add(expireDays, 'days').toISOString(),
      id: window.nd_user.info.get('_id')
    };
    console.log(`Writing ${key}_${key_suffix} to local storage`);
    localStorage.setItem(`${key}_${key_suffix}`, JSON.stringify(payload));
  }
};

export default NDLocalStorage;
