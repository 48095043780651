import Cookie from 'js-cookie';
/**
 * Object for handling API calls
 */
class ND_User_API {
  token = false;

  /**
   * Sets the JWT token for requests
   * @param {String} token
   */
  set_token (token) {
    Object.assign(this, { token });
    return this;
  }

  set_user_endpoint (_id) {
    this.user_endpoint = `user/${_id}`;
  }

  async get_token () {
    try {
      if (this.token) {
        return this.token;
      }

      const d = await this.call('authorize', 'POST', false, {
        encrypted_customer_registration_id: Cookie.get('igmRegID')
      });

      if (d.success) {
        this.set_token(d.data.token);
        this.set_user_endpoint(d.data._id);
        return d.data.token;
      }

      throw new Error(d.error.message);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  /**
   * Verifies the token is set
   */
  has_token () {
    return !!this.token;
  }

  /**
   * Fires a get request to the API
   *
   * @param {String} endpoint
   * @param {Object} body
   */
  get (endpoint, auth = true) {
    return this.call(endpoint, 'GET', auth, false);
  }

  /**
   * Sends a payload to the API via POST request
   *
   * @param {String} endpoint
   * @param {Object} body
   */
  post (endpoint, body, auth = true) {
    return this.call(endpoint, 'POST', auth, body);
  }

  /**
   * Sends a request to the API using the method specified and includes the body payload specified
   *
   * @param {String} endpoint
   * @param {String} method
   * @param {Object} body
   */
  async call (endpoint, method = 'GET', auth = true, body = false) {
    const headers = {
      Accepts: 'application/json',
      'Content-Type': 'application/json'
    };

    if (auth) {
      Object.assign(headers, {
        Authorization: `Bearer ${await this.get_token()}`
      });
    }

    const payload = {
      method,
      headers
    };

    if (
      endpoint === `${this.user_endpoint}/` ||
      endpoint.indexOf('login') > -1
    ) {
      payload.credentials = 'include';
    } else {
      console.log('Leaving creds out');
    }
    if (body) {
      Object.assign(payload, {
        body: JSON.stringify(body)
      });
    }

    return fetch(`${this.api_url()}/${endpoint}`, payload).then((r) => r.json());
  }

  /**
   * Generates the correct API URL based on environment
   */
  api_url () {
    const hosts = {
      local: 'https://local.api.newsday.com/users/api/v2',
      stage: 'https://stage.api.newsday.com/users/api/v2',
      prod: 'https://api.newsday.com/users/api/v2'
    };

    const matches = location.hostname.match(/(local|stage)/);
    let env = 'prod';

    if (matches && matches.length > 0) {
      env = matches[1];
    }

    return hosts[env];
  }
}
export default ND_User_API;
