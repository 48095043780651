let env = 'prod';

if (/(local|stag|test)/.test(location.hostname)) {
  const matches = location.hostname.match(/(local|stag|test)/);

  env = matches[1];

  if (env === 'local' || env.indexOf('stag') > -1) {
    env = 'stage';
  }

  if (env === 'test') {
    env = 'prod';
  }
}

class ND_MG2 {
  constructor () {
    this.checkCookie();
    this.setUp();
    this.binds();
  }

  getCookie (name) {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  checkCookie () {
    const name =
      env === 'prod'
        ? 'pS_NSDY_NEWSDAY_FIRST_PROD'
        : 'pS_NSDY_NEWSDAY_FIRST_STAGE';
    try {
      JSON.parse(this.getCookie(name));
    } catch (e) {
      localStorage.removeItem(`Cookie_${name}`);
      document.cookie =
        `${name}=will_be_deleted; domain=.newsday.com; max-age=-1`;
    }
  }

  setUp () {
    if (!window.MG2Loader || !window.MG2Loader.init) {
      setTimeout(() => {
        this.setUp();
      }, 250);
    } else {
      this.init();
    }
  }

  settingsKey () {
    let key = 'webSite';

    if (/tv/.test(location.hostname)) {
      key = 'NVID,Video';
    }

    return key;
  }

  init () {
    this.connext = window.MG2Loader.init({
      plugins: [
        {
          name: 'FP',
          initOptions: {
            version: 'newsday',
            environment: env
          }
        },
        {
          name: 'DL',
          initOptions: {
            version: 'newsday',
            collectors: ['connext'],
            tagManager: 'GTM',
            containerId: 'GTM-P2SHJCV'
          }
        },
        {
          name: 'NXT',
          initOptions: {
            clientCode: 'newsday',
            environment: env,
            settingsKey: this.settingsKey(),
            siteCode: 'NSDY',
            configCode: 'NEWSDAY_FIRST',
            userAuthenticationTime: 360,
            runSettings: {
              runOffset: 3000
            },
            debug: window.location.search.indexOf('debug=true') > -1,
            silentmode: true,
            publicEventHandlers: {
              onInit: (e) => this.emitEvent('onInit', e),
              onAdblockFound: (e) => this.emitEvent('onAdblockFound', e),
              onMeterLevelSet: (e) => this.emitEvent('onMeterLevelSet', e),
              onDynamicMeterFound: (e) => this.emitEvent('onDynamicMeterFound', e),
              onCampaignFound: (e) => this.emitEvent('onCampaignFound', e),
              onConversationDetermined: (e) => this.emitEvent('onConversationDetermined', e),
              onAccessGranted: (e) => this.emitEvent('onAccessGranted', e),
              onActionShown: (e) => this.emitEvent('onActionShown', e),
              onActionClosed: (e) => this.emitEvent('onActionClosed', e),
              onActivationFormShown: (e) => this.emitEvent('onActivationFormShown', e),
              onRegistrationShown: (e) => this.emitEvent('onRegistrationShown', e),
              onActivationFormClosed: (e) => this.emitEvent('onActivationFormClosed', e),
              onButtonClick: (e) => this.emitEvent('onButtonClick', e),
              onNotAuthorized: (e) => this.emitEvent('onNotAuthorized', e),
              onAuthorized: (e) => this.emitEvent('onAuthorized', e),
              onHasAccess: (e) => this.emitEvent('onHasAccess', e),
              onHasAccessNotEntitled: (e) => this.emitEvent('onHasAccessNotEntitled', e),
              onHasNoActiveSubscription: (e) => this.emitEvent('onHasNoActiveSubscription', e),
              onLoggedIn: (e) => this.emitEvent('onLoggedIn', e),
              onLoginSuccess: (e) => this.emitEvent('onLoginSuccess', e),
              onLoginError: (e) => this.emitEvent('onLoginError', e),
              onLoginShown: (e) => this.emitEvent('onLoginShown', e),
              onActivationLinkSuccessStepShown: (e) => this.emitEvent('onActivationLinkSuccessStepShown', e),
              onAccessTemplateShown: (e) => this.emitEvent('onAccessTemplateShown', e),
              onAccessTemplateClosed: (e) => this.emitEvent('onAccessTemplateClosed', e),
              onPaywallShown: (e) => {
                this.emitEvent('onPaywallShown', e);
                this.paywallId = e.actionIdPrefix + (e.EventData?.Id ?? '');
              },
              onCriticalError: (e) => this.emitEvent('onCriticalError', e),
              onFlittzButtonClick: (e) => this.emitEvent('onFlittzButtonClick', e),
              onRun: (e) => this.emitEvent('onRun', e),
              onActivationLoginStepShown: (e) => this.toggleActivationLogin(true),
              onActivationLoginStepClosed: (e) => this.toggleActivationLogin(false)
            }
          }
        }
      ]
    });
  }

  toggleActivationLogin (displayed) {
    if (!!this.paywallId && document.querySelectorAll(`#${this.paywallId}`).length > 0) {
      if (displayed) {
        document.getElementById(this.paywallId).style.display = 'none';
      } else if (!displayed) {
        document.getElementById(this.paywallId).style.display = 'block';
      }
    }
  }

  emitEvent (name, data) {
    name = `nd_mg2_${name}`;
    const evt = new CustomEvent(name, { detail: data });
    console.log(`Emitting ${name}`);
    console.log(evt);

    window.dispatchEvent(evt);
  }

  binds () {
    window.addEventListener('nd_mg2_onActivationFormShown', (e) => {
      if (!!window.action_name && window.action_name.indexOf('in-line') > -1) {
        return false;
      }
      const modals = document.querySelectorAll(
        '.paywall.modal, .nwsd-new.modal'
      );

      modals.forEach((m) => {
        m.style.display = 'none';
      });
    });

    window.addEventListener('nd_mg2_onActivationFormClosed', (e) => {
      if (!!window.action_name && window.action_name.indexOf('in-line') > -1) {
        return false;
      }
      const modals = document.querySelectorAll(
        '.paywall.modal, .nwsd-new.modal'
      );

      modals.forEach((m) => {
        m.style.display = 'block';
      });
    });

    window.addEventListener('nd_mg2_onActivationFormShown', (e) => {
      this.bindPasswordToggle();
    });

    window.addEventListener('nd_mg2_onRegistrationShown', (e) => {
      this.bindPasswordToggle();
    });
  }

  bindPasswordToggle () {
    const fields = document.querySelectorAll('.toggle-password');
    fields.forEach((f) => {
      f.addEventListener('click', (e) => {
        const _eye = e.target.closest('.password-field').querySelector('use');
        if (_eye.getAttribute('href').includes('eye-slash')) {
          const src = _eye.getAttribute('href').replace('eye-slash', 'eye');
          _eye.setAttribute('href', src);
        } else {
          const src = _eye.getAttribute('href').replace('eye', 'eye-slash');
          _eye.setAttribute('href', src);
        }

        const input = e.target
          .closest('.password-field')
          .querySelector('input');
        if (input.type === 'password') {
          input.type = 'text';
          e.target['aria-label'] = 'hide password';
        } else {
          input.type = 'password';
          e.target['aria-label'] = 'show password';
        }
      });
    });
  }
}

export default ND_MG2;
