import Cookie from 'js-cookie';

class ND_User_Analytics {
  ispreview = false;
  events = [];
  allowListChecked = false;
  adobe_data = {
    page_data: false,
    user_data: false,
    complete: false
  };

  constructor (user_obj) {
    window.appEventData = window.appEventData || [];
    this.binds();

    Object.assign(this, { user_obj });

    if (window.ndGlobal && typeof window.ndGlobal.isPreview !== 'undefined') {
      this.ispreview = window.ndGlobal.isPreview.toString();
    }

    // cue up the free:no mg2 call to be canceled later on success (paper and cancellation flow omitted)
    if (
      !/paper[.]/i.test(window.location.href) &&
      !/\/cancel-subscription/i.test(window.location.href)
    ) {
      this.meter_timeout = setTimeout(() => {
        this.meter_level_check();
      }, 15 * 1000);
    }

    window.PushlySDK = window.PushlySDK || [];
    window.pushly = function () {
      window.PushlySDK.push(arguments);
    };

    // this.setPushlyData();
    window.addEventListener('nd_user_data_populated', () => {
      this.setPushlyData();
    });
    window.addEventListener('OneTrustGroupsUpdated', () => {
      if (Cookie.get('OptanonAlertBoxClosed')) {
        this.setPushlyData();
      }
    });
  }

  send_adobe (updated = {}) {
    Object.assign(this.adobe_data, updated);

    const { page_data, user_data, complete } = this.adobe_data;

    if (page_data && user_data && !complete) {
      window.appEventData.push({
        event: 'pageDataCompleted'
      });
      this.adobe_data.complete = true;
    }
  }

  /**
   * Fired if 8 seconds passes and MG2 doesn't load. This is cleared by onInit
   */
  meter_level_check () {
    console.log("MG2 didn't load soon enough");
    window.mtr_lvl = 'free:no mg2';
    this.send_adobe({ user_data: true });
    this.fireAdobeTrack();
  }

  /**
   * Enqueues events until connext is ready
   * @param {Function} func
   */
  e (func = false) {
    if (func) {
      this.events.push({ completed: false, func });
    }

    if (this.analytics_ready) {
      this.events
        .filter((e) => !e.completed)
        .forEach((e) => {
          e.func();
          e.completed = true;
        });
    }
  }

  getHostMap () {
    const host_map = {
      local: {
        site: 'https://stage.newsday.com',
        tools: 'https://local.tools.newsday.com'
      },
      stage: {
        site: 'https://stage.newsday.com',
        tools: 'https://stage.tools.newsday.com'
      },
      prod: {
        site: 'https://www.newsday.com',
        tools: 'https://tools.newsday.com'
      }
    };

    let env = 'prod';

    if (
      location.hostname.indexOf('stage') > -1 ||
      location.pathname.indexOf('/stage/') > -1
    ) {
      env = 'stage';
    }

    if (location.hostname.indexOf('local') > -1) {
      env = 'local';
    }
    return host_map[env];
  }

  checkGating () {
    if (typeof window.allowListInfo !== 'undefined') {
      console.log('stopping interval === ', window.allowListInfo);
    } else {
      setTimeout(() => {
        console.log('checking again === ');
        this.checkGating();
      }, 200);
    }
  }

  checkSatellite (event) {
    if (typeof window._satellite.track === 'function') {
      window._satellite.track(event);
    } else {
      setTimeout(() => {
        console.log('=== checking again ');
        this.checkSatellite(event);
      }, 200);
    }
  }

  setPushlyData () {
    // assume they're a non-sub
    const profile_data = { is_paying_customer: false };

    // if the login cookie is present and the access_level data is available, update the profile data with the requisite info
    if (Cookie.get('igmRegID') && this.user_obj?.info?.get('access_level')) {
      Object.assign(profile_data, {
        is_paying_customer: /print|digital|video/i.test(
          this.user_obj.info.get('access_level')
        ),
        interests: this.user_obj.interests
          .get()
          .map((i) => i.interest)
          .join(','),
        external_id: this.user_obj.info.get(
          'encrypted_customer_registration_id'
        )
      });
    }
    window.pushly('profile', profile_data); // send the data to Pushly
    window.pushly('show_prompt'); // the prompt has auto-show disabled, thus it requires this command to display it
  }

  /* check against allow list on Tyrion and save data in nd_user.entity for analytics */
  checkAllowList (payload) {
    if (typeof window.allowListInfo !== 'undefined') {
      Object.assign(payload.allowList, window.allowListInfo.allow_list);
      if (window.allowListInfo.allow_list.entity) {
        payload.entity =
          `${window.allowListInfo.allow_list.entity.entity
          }, ${
          window.allowListInfo.allow_list.entity.type}`;
      }
      this.sendPayLoad(payload);
    } else {
      setTimeout(() => {
        this.checkAllowList(payload);
      }, 200);
    }
  }

  sendPayLoad (payload) {
    window.appEventData.push({
      event: 'userPageData',
      pageinfo: payload
    });
    this.send_adobe({ page_data: true });
  }

  fireAdobeTrack () {
    console.log('pushing page data');
    const payload = {
      meterLevel: window.mtr_lvl, // this is the window.mtr_lvl that is set 'free' in the default
      allowList: {}
    };
    if (
      window.location.href.indexOf('paper.newsday.com') === -1 &&
      !this.allowListChecked &&
      !this.user_obj.isLoggedIn()
    ) {
      this.checkAllowList(payload);
    } else {
      this.sendPayLoad(payload);
    }
  }

  binds () {
    const user_data_timeout = setTimeout(() => {
      this.send_adobe({ user_data: true });
    }, 10 * 1000);
    window.addEventListener('nd_user_object_ready', (e) => {
      if (!this.user_obj.isLoggedIn()) {
        clearTimeout(user_data_timeout);
        this.send_adobe({ user_data: true });
      }
    });

    window.addEventListener('nd_user_data_populated', (e) => {
      const {
        encrypted_customer_registration_id,
        email,
        access_level,
        zipcode,
        city
      } = this.user_obj.info.get();
      this.user_obj.interests
        .fetch()
        .then(() => {
          console.log('Pushing interests and user info...');
          window.appEventData.push({
            event: 'userData',
            user: {
              id: encrypted_customer_registration_id,
              email,
              accessLevel: access_level,
              interests: this.user_obj.interests
                .get()
                .map((i) => i.interest)
                .join(','),
              zipCode: zipcode,
              city
            }
          });
          clearTimeout(user_data_timeout);
          this.send_adobe({ user_data: true });
        })
        .catch(() => {
          console.log('Pushing user info...');
          window.appEventData.push({
            event: 'userData',
            user: {
              id: encrypted_customer_registration_id,
              email,
              accessLevel: access_level,
              interests: [],
              zipCode: zipcode,
              city
            }
          });
          clearTimeout(user_data_timeout);
          this.send_adobe({ user_data: true });
        });
    });

    window.addEventListener('nd_mg2_onInit', (e) => {
      // when MG2 is ready, clear the 8 second timeout
      console.log('MG2 loading, clearing timeout');
      clearTimeout(this.meter_timeout);
    });

    window.addEventListener('nd_user_ready', (e) => {
      this.analytics_ready = true;
      this.e();
    });

    window.addEventListener('nd_mg2_onMeterLevelSet', (e) => {
      clearTimeout(this.meter_timeout);
      this.e(() => {
        this.onMeterLevelSet(e);
      });
    });

    window.addEventListener('nd_mg2_onConversationDetermined', (e) => {
      clearTimeout(this.meter_timeout);
      this.e(() => {
        this.onConversationDetermined(e);
      });
    });

    window.addEventListener('nd_mg2_onAccessGranted', (e) => {
      clearTimeout(this.meter_timeout);
      this.e(() => {
        this.onAccessGranted(e);
      });
    });

    window.addEventListener('nd_mg2_onActionShown', (e) => {
      // track onactionshown on google g4
      window.gtag('event', 'onactionshown');

      this.e(() => {
        this.onActionShown(e);
      });
    });

    window.addEventListener('nd_mg2_onLoginSuccess', (e) => {
      clearTimeout(this.meter_timeout);
      // set a flag to reload the page after the login event fires
      window.naviga_reload = true;
      localStorage.setItem('c_lnsccs', 'success');
      window._satellite.track('login');
    });

    window.addEventListener('nd_mg2_onHasAccess', (e) => {
      // if the user has access after logging in, reload the page
      if (window.naviga_reload) {
        location.reload();
      }
    });

    window.addEventListener('nd_mg2_onLoginError', (e) => {
      clearTimeout(this.meter_timeout);
      this.e(() => {
        localStorage.setItem('c_lnsccs', e.detail.EventData.ErrorMessage);
        window._satellite.track('login');
      });
    });
  }

  /**
   * Fired when ConneXt has established the meter level
   */

  onMeterLevelSet (e) {
    console.info('onMeterLevelSet.Callback', e); // set on every page
    window.mtr_lvl = e.detail.EventData.Level;
    if (window.mtr_lvl === 'Free') {
      window.mtr_lvl = 'free';
    } else if (window.mtr_lvl === 'Metered') {
      window.mtr_lvl = 'metered';
    } else if (window.mtr_lvl === 'Premium') {
      window.mtr_lvl = 'restricted';
    } else {
      window.mtr_lvl = 'free';
    }

    // adobe metrics
    if (window.mtr_lvl === 'free') {
      this.fireAdobeTrack();
    }
    const mg2LoginSet = new Event('mg2LoginSet', {
      bubbles: true
    });
    document.dispatchEvent(mg2LoginSet);
  }

  onConversationDetermined (e) {
    console.info('onConversationDetermined.Callback', e);
    /* deterimine if conversation is metered. If yes move to send adobe metrics based on viewsleft
     * otherwise do nothing because call is sent as free above in onMeterLevelSet
     *
     * if conversation is Premium, mtr_lvl=restricted...***
     *
     * */
    window.conv_name = e.detail.Conversation.Name;
    const thisMeterLevel = e.detail.MeterLevel;
    if (thisMeterLevel === 'Metered') {
      let viewsleft = e.detail.Conversation.Properties.ArticleLeft;
      if (viewsleft === 'unlimited') {
        viewsleft = 1;
      }
      viewsleft = parseInt(e.detail.Conversation.Properties.ArticleLeft, 10);
      if (viewsleft > 0) {
        window.mtr_lvl = 'metered:free';
        // adobe metrics
        this.fireAdobeTrack();
      } else {
        // adobe metrics
        window.mtr_lvl = 'metered';
        this.fireAdobeTrack();
      }
    } else if (thisMeterLevel === 'Premium') {
      // adobe metrics
      window.mtr_lvl = 'restricted';
      this.fireAdobeTrack();
    }
  }

  onAccessGranted (e) {
    /* fires when user on whitelist (onConversationDetermined/onMeterLevelSet NOT fired) */
    console.info('onAccessGranted.Callback', e);
    window.mtr_lvl = 'free:whitelist';
    this.fireAdobeTrack();
  }

  onActionShown (e) {
    if (document.querySelector('.closebtn')) {
      document.querySelector('.closebtn').click();
    }
    window.action_name = e.detail.EventData.Name.toLowerCase();
    window.conv_name = e.detail.Conversation.Name;
    window.camp_name = e.detail.CampaignName;
    window.mtr_lvl = e.detail.MeterLevel;
    this.checkSatellite('onactionshown');
  }
}

export default ND_User_Analytics;
