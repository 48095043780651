import React from 'react';
import User_Component from './user_component';
import zipmap from '../inc/zip';

class WeatherIcon extends User_Component {
  state = {
    is_logged_in: false,
    zip: false, // islip by default
    data: false,
    loading: true
  };

  /**
     * Uses user data and the current environment to determine the appropriate weather URLs for XHR and web page links
     *
     * @returns {Object} Weather related URLs
     */
  get_urls () {
    const { zip } = this.state;
    const zip_record = zipmap({ zip });
    const location = [zip_record.city, zip_record.state].join('-').replace(/[^A-Za-z0-9]+/g, '-').toLowerCase();

    const urls = {
      weather_page: `${this.site_url()}/weather/${location}`,
      api: `${this.site_url()}/sunWeather?requestType=currentConditions&lat=${zip_record.geo.lat}&long=${zip_record.geo.lon}`
    };

    return urls;
  }

  /**
     * Captures the data for this user
     */
  get_data () {
    const { api } = this.get_urls();
    this.init = true; // sets init to true the first time this is called
    fetch(api).then((r) => r.json())
      .then((d) => this.setState({ data: d, loading: false }))
      .catch((e) => console.log(e));
  }

  /**
     * Extends the base component method to begin the XHR requests for weather data
     * when this component is injected into the DOM
     */
  componentDidMount () {
    super.componentDidMount();

    window.addEventListener('nd_weather_default_zip_change', (e) => {
      console.log('Default weather change');
      this.assess_login();
    });
  }

  /**
     * Called whenever setState completes
     *
     * This determines if new weather data needs to be called by checking
     * if the zip code has changed in the state
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     */
  componentDidUpdate (prevProps, prevState) {
    console.log('Component did update');
    console.log('prevProps', prevProps);
    console.log('prevState', prevState);
    console.log('current state', this.state);
    if (prevState.zip !== this.state.zip) {
      console.log('Updating weather data because the zip code changed');
      this.get_data();
    }
  }

  /**
     * Whenever an event is called that re-assesses login we also refresh the zip code
     */
  assess_login () {
    const { user_obj } = this.props;
    let { zip } = this.state;

    if (user_obj.isLoggedIn() && user_obj.info.populated && user_obj.info.get('zipcode')) {
      zip = user_obj.info.get('zipcode');
    } else {
      zip = 11751;
    }

    this.setState({ is_logged_in: user_obj.isLoggedIn(), zip });
  }

  render () {
    const { data, loading } = this.state;

    if (loading) {
      return <div />;
    }

    const { weather_page } = this.get_urls();

    if (!data) {
      return (
        <a href={weather_page} aria-label="weather">
          <span className="weatherIcon" />
        </a>
      );
    }

    return (
      <a href={weather_page} aria-label="weather">
        <span className="weatherIcon">
          <i className={`wicon${data.current_conditions.observation.wx_icon}`} />
          <em>
            {data.current_conditions.observation.temp}
            °
          </em>
        </span>
      </a>
    );
  }
}

export default WeatherIcon;
