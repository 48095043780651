import userStorage from './localStorage';

/**
 * This payload class is used to define default methods for any payload object parents that could be sent back to the API
 *
 * @param {String} property_name The name of the property as it should be defined in the request
 * @param {Object} api The Object that contains the API settings
 */

class ND_User_Data_Payload {
  populated = false;
  data = {};

  constructor (endpoint, api) {
    Object.assign(this, { endpoint, api });
  }

  async fetch () {
    try {
      const { api } = this;
      if (
        this.endpoint &&
        userStorage.getItem(`nd_user_data_${this.endpoint}`)
      ) {
        console.log(`Retrieving ${this.endpoint} from local storage`);
        Object.assign(this, {
          populated: true,
          data: userStorage.getItem(`nd_user_data_${this.endpoint}`)
        });
        return;
      }
      console.log(`Retrieving ${this.endpoint} from API`);
      const d = await api
        .get_token()
        .then(() => api.get(`${api.user_endpoint}/${this.endpoint}`));

      if (d.success) {
        const { populated } = this;
        Object.assign(this, {
          populated: true,
          data: d.data
        });
        if (!populated) {
          if (!this.endpoint) {
            window.dispatchEvent(new CustomEvent('nd_user_data_populated'));
          } else {
            window.dispatchEvent(
              new CustomEvent(`nd_user_data_${this.endpoint}_populated`)
            );
          }
        }

        if (this.endpoint) {
          // if we've made it this far, update local storage
          userStorage.setItem(`nd_user_data_${this.endpoint}`, d.data);
        }

        d.data.token = this.api.token;
        return d;
      }
      throw new Error(d.error.message);
    } catch (e) {
      console.log('>> FETCH ERROR >>', e);
      throw e;
    }
  }

  /**
   * Gets the instance property from the data object
   *
   * @param {String} property
   */
  get (property = false) {
    if (!property) {
      return this.data;
    }
    return this.data[property];
  }

  /**
   * Sends current object state to backend for persistent storage
   */
  async save () {
    try {
      const { api, data, endpoint } = this;
      console.log('Data');
      console.log(data);
      const d = await api.post(`${api.user_endpoint}/${endpoint}`, data);

      if (d.success) {
        if (this.endpoint) {
          localStorage.setItem(
            `nd_user_data_${this.endpoint}`,
            JSON.stringify(d.data)
          );
        }
        this.data = d.data;
        return;
      }
      throw new Error(d.error.message);
    } catch (e) {
      console.log('>> SAVE ERROR >>', e);
      throw e;
    }
  }

  /**
   * Updates the in-memory object and then saves the changes to the backend for persistent storage
   *
   * @param {Object} data
   */
  update (data = {}) {
    this.set(data);
    return this.save();
  }

  /**
   * Updates the in-memory object
   * @param {Object} data
   */
  set (data = {}) {
    Object.assign(this.data, data);
    return this;
  }

  clear () {
    this.data = {};
    this.populated = false;
  }
}

export default ND_User_Data_Payload;
