import React from 'react';
import User_Component from './user_component';

class HeadR extends User_Component {
  constructor (props) {
    super(props);
    const { user_obj } = this.props;

    /**
         * If the user has the login cookies, show the initials and account buttons right away
         */
    Object.assign(this, {
      state: {
        is_logged_in: user_obj.isLoggedIn(),
        show: user_obj.isLoggedIn()
      }
    });
  }

  log_in () {
    return (
      <a href="#" className="not-loggedin logInbtn MG2login active" id="MG2login" data-mg2-action="activation">
        <strong>
          <em>Log in</em>
        </strong>
      </a>
    );
  }

  initials () {
    const { user_obj } = this.props;
    const initials = user_obj.info.initials();

    if (!initials) {
      return <i className="fa fa-user" aria-hidden="true" />;
    }

    return initials;
  }

  account_button () {
    const { user_obj } = this.props;

    if (user_obj.isInAppLoggedIn() || user_obj.isInNwslSub()) {
      console.log('Newsletter sub');
      return null;
    }
    return (
      <button type="button" className="loggedin MG2MyAccount active" aria-label="Account">
        <span>{this.initials()}</span>
        <em>ACCOUNT</em>
      </button>
    );
  }

  subscribe_button () {
    const { user_obj } = this.props;

    if (user_obj.isInNwslSub()) {
      return null;
    }

    return (
      <div className="subacct MG2Subscribe active">
        <a href="/subscribe" className="tBtn subacct active" id="MG2Subscribe">
          <strong>
            <em>Subscribe</em>
          </strong>
        </a>
      </div>
    );
  }

  /**
     * Login is assessed when the component mounts and after many of the MG2 events are emitted
     *
     * For headr, we show everything immediately when the user starts their session logged in
     * Users who are not signed in on page load do not see the login button until MG2 is on the page
     */
  assess_login () {
    const { user_obj: { is_logged_in, mg2_ready } } = this.props;
    let { show } = this.state;

    if (!show && !is_logged_in) { // only allow mg2's ready state to influence headr visibility when the user isn't logged in on page load
      show = mg2_ready;
    }

    this.setState({ is_logged_in, show });
  }

  /**
     * Generates a list of classes for logStatus
     *
     * @returns {String}
     */
  status_class () {
    const { show } = this.state;

    const classes = ['logStatus'];

    if (show) {
      classes.push('active');
    }

    return classes.join(' ');
  }

  render () {
    const { user_obj } = this.props;

    if (user_obj.isLoggedIn()) {
      console.log('Logged in');
      return (
        <div className={this.status_class()}>
          {this.account_button()}
        </div>
      );
    }
    console.log('Logged out');
    return (
      <div className={this.status_class()}>
        {this.log_in()}
        {this.subscribe_button()}
      </div>
    );
  }
}

export default HeadR;
