import React from 'react';
import dayjs from '../inc/dayjs';
import User_Component from './user_component';
import zipmap from '../inc/zip';

class Weather extends User_Component {
  state = {
    is_logged_in: false,
    zip: false, // islip by default
    data: false,
    loading: true
  };

  /**
   * This outputs an appropriate greeting based on the time of day
   *
   * Good morning between 0 and 12
   * Good afternoon between 12 and 17
   * Good evening between 17 and 0
   *
   * @returns {String} greeting
   */
  salutation () {
    const now = dayjs();
    const afternoon = 12;
    const evening = 17;
    let salutation = 'Good Morning';

    if (now.hours() >= afternoon) {
      salutation = 'Good Afternoon';
    }

    if (now.hours() >= evening) {
      salutation = 'Good Evening';
    }

    return salutation;
  }

  /**
   * Uses user data and the current environment to determine the appropriate weather URLs for XHR and web page links
   *
   * @returns {Object} Weather related URLs
   */
  get_urls () {
    const { zip } = this.state;
    const zip_record = zipmap({ zip });
    const location = [zip_record.city, zip_record.state].join('-').replace(/[^A-Za-z0-9]+/g, '-').toLowerCase();

    const urls = {
      weather_page: `${this.site_url()}/weather/${location}`,
      api: `${this.site_url()}/sunWeather?requestType=currentConditions&lat=${zip_record.geo.lat}&long=${zip_record.geo.lon}`
    };

    return urls;
  }

  /**
   * Captures the data for this user
   */
  get_data () {
    const { api } = this.get_urls();
    this.init = true; // sets init to true the first time this is called
    fetch(api).then((r) => r.json())
      .then((d) => this.setState({ data: d, loading: false }))
      .catch((e) => console.log(e));
  }

  /**
   * Extends the base component method to begin the XHR requests for weather data
   * when this component is injected into the DOM
   */
  componentDidMount () {
    super.componentDidMount();

    window.addEventListener('nd_weather_default_zip_change', (e) => {
      console.log('Default weather change');
      this.assess_login();
    });
  }

  /**
   * Called whenever setState completes
   *
   * This determines if new weather data needs to be called by checking
   * if the zip code has changed in the state
   *
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate (prevProps, prevState) {
    console.log('Component did update');
    console.log('prevProps', prevProps);
    console.log('prevState', prevState);
    console.log('current state', this.state);
    if (prevState.zip !== this.state.zip) {
      console.log('Updating weather data because the zip code changed');
      this.get_data();
    }
  }

  /**
   * Whenever an event is called that re-assesses login we also refresh the zip code
   */
  assess_login () {
    const { user_obj } = this.props;
    let { zip } = this.state;

    if (user_obj.isLoggedIn() && user_obj.info.populated && user_obj.info.get('zipcode')) {
      zip = user_obj.info.get('zipcode');
    } else {
      zip = 11751;
    }

    console.log('using zip');
    console.log(zip);
    this.setState({ is_logged_in: user_obj.isLoggedIn(), zip });
  }

  name () {
    const { is_logged_in } = this.state;
    const { user_obj } = this.props;

    if (is_logged_in) {
      return (<b className="loggedin active">{user_obj.info.get('first_name')}</b>);
    }
  }

  render () {
    const { data, loading } = this.state;
    const date = dayjs();

    if (loading) {
      return <div />;
    }

    const { weather_page } = this.get_urls();

    // Weather forecast icons mapping for SVG
    const iconMap = [
      'wi-tornado',
      'wi-hurricane',
      'wi-hurricane',
      'wi-thunderstorm',
      'wi-thunderstorm',
      'wi-rain-mix',
      'wi-sleet',
      'wi-sleet',
      'wi-rain-mix',
      'wi-sprinkle',
      'wi-rain-mix',
      'wi-sprinkle',
      'wi-rain',
      'wi-snow',
      'wi-snow',
      'wi-snow-wind',
      'wi-snow',
      'wi-hail',
      'wi-sleet',
      'wi-sandstorm',
      'wi-fog',
      'wi-fog',
      'wi-smoke',
      'wi-windy',
      'wi-windy',
      'wi-snowflake-cold',
      'wi-cloudy',
      'wi-night-alt-cloudy-high',
      'wi-day-cloudy-high',
      'wi-night-alt-cloudy',
      'wi-day-cloudy',
      'wi-night-clear',
      'wi-day-sunny',
      'wi-night-clear',
      'wi-day-sunny',
      'wi-day-rain-mix',
      'wi-hot',
      'wi-day-thunderstorm',
      'wi-day-thunderstorm',
      'wi-day-rain',
      'wi-rain',
      'wi-day-snow',
      'wi-snow',
      'wi-snow',
      null,
      'wi-night-rain',
      'wi-night-snow',
      'wi-night-thunderstorm'
    ];

    if (!data) {
      return (
        <a href={weather_page} aria-label="weather">
          <span className="weatherIcon" />
          <span className="weather-abso">
            <time dateTime={date.format('YYYY-MM-DD')}>{date.format('MM/DD/YYYY')}</time>
            <strong>{this.salutation()}</strong>
            {this.name()}
          </span>
        </a>
      );
    }

    return (
      <a href={weather_page} aria-label="weather">
        <svg className="WeatherIcon">
          <use href={`/_common/img/weather-icon-sprites.svg#${iconMap[parseInt(data.current_conditions.observation.wx_icon, 10)]}`} />
        </svg>
        <em>
          {data.current_conditions.observation.temp}
          °
        </em>
        <span className="weather-abso">
          <time dateTime={date.format('YYYY-MM-DD')}>{date.format('MM/DD/YYYY')}</time>
          <strong>{this.salutation()}</strong>
          {this.name()}
        </span>
      </a>
    );
  }
}

export default Weather;
