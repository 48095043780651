import ND_User_Payload from './payload';

class ND_User_Data extends ND_User_Payload {
  fetch () {
    const { api } = this;
    return new Promise((resolve, reject) => {
      api
        .get(`clientinfo`, false)
        .then((d) => {
          if (d.success) {
            const { populated } = this;
            Object.assign(this, {
              populated: true,
              data: d.data
            });
            if (!populated) {
              window.dispatchEvent(
                new CustomEvent(`nd_user_data_client_populated`)
              );
            }

            resolve();
          } else {
            reject(d);
          }
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }

  ip () {
    const { ip } = this.get();

    return ip[0];
  }
}

export default ND_User_Data;
