// This module checks for URL hash that contains user's encrypted email (lctg) when user comes to page via newsletter click through

export default function Live_Ramp () {
  const atsHandler = (timer) => {
    const interval = 500;
    if (window.ats?.setAdditionalData) {
      let email_encrypted = window.location.hash.split('&').filter((item) => /lctg/.test(item))[0];
      if (email_encrypted) {
        email_encrypted = email_encrypted.substring('lctg='.length, email_encrypted.length);
        window.ats.setAdditionalData({
          id: [email_encrypted],
          type: 'emailHashes'
        });
      }
    } else if (timer > 0) {
      setTimeout(() => {
        atsHandler(timer - interval);
      }, interval);
    }
  };

  atsHandler(5000);
}
