import zipcodes from 'zipcodes';

export default function Zip (opts) {
  const { zip, state, city } = opts;
  let lookup;

  if (zip) {
    console.log('Looking up via zipcode');
    lookup = zipcodes.lookup(zip);
  } else if (state && city) {
    console.log('Looking up via city and state');
    lookup = zipcodes.lookup(city, state);
  } else {
    throw new Error('You must provide an object with zip or city and state');
  }

  if (lookup) {
    const { zip, latitude, longitude, city, state } = lookup;
    console.log(lookup);
    return {
      name: `${city}, ${state}`,
      city,
      state,
      zip,
      geo: {
        lat: latitude,
        lon: longitude
      }
    };
  }
  throw new Error(
    'Could not find geographical data for the location provided'
  );
}
