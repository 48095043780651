import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { ndGlobal } = window;

class User_Component extends Component {
  state = {
    is_logged_in: false
  };

  static propTypes = {
    user_obj: PropTypes.object.isRequired
  };

  /**
     * When this component is injected into the DOM
     *
     * This method immediately assesses login status from the nd_user object booleans
     * It also sets up a series of MG2 event listeners that, when emitted, also invoke the assess_login method
     * Assess Login is used in components to determine their state, which will influence what different users see
     */
  componentDidMount () {
    this.assess_login();

    const assess_login_events = ['onLoggedOut', 'onLoggedIn', 'onHasAccessNotEntitled', 'onHasNoActiveSubscription', 'onNotAuthorized', 'onHasAccess', 'onAuthorized'];

    assess_login_events.forEach((ev) => {
      window.addEventListener(`nd_mg2_${ev}`, (e) => {
        this.assess_login();
      });
    });

    window.addEventListener(`nd_user_data_populated`, (e) => {
      this.assess_login();
    });
  }

  /**
     * Checks the nd_user booleans for login and activation state
     */
  assess_login () {
    const { user_obj, user_obj: { is_activated, mg2_ready } } = this.props;

    this.setState({ is_logged_in: user_obj.isLoggedIn(), is_activated, mg2_ready, user_data_populated: this.props.user_obj.info.populated });
  }

  /**
     * Uses ndGlobal to assess the appropriate newsday.com domain for links and XHR requests
     *
     * @returns {String} Path to this environment's newsday.com site
     */
  site_url () {
    const hosts = {
      local: 'https://stage.newsday.com',
      stage: 'https://stage.newsday.com',
      prod: 'https://www.newsday.com'
    };

    return hosts[ndGlobal.env];
  }

  /**
     * All react components must have a render function. This outputs an empty div
     *
     * ES6 classes override methods when children redeclare them. This render method will never be used
     * @returns
     */
  render () {
    return <div />;
  }
}

export default User_Component;
