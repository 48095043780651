import ND_User_Payload from './payload';

class ND_User_Data extends ND_User_Payload {
  /**
   * Gets the user's first and last initial
   */
  initials () {
    if (!this.populated) {
      return '';
    }
    const first_name = this.get('first_name');
    const last_name = this.get('last_name');

    if (first_name && last_name) {
      return `${first_name[0].toUpperCase()}${last_name[0].toUpperCase()}`;
    }

    return '';
  }

  /**
   * Generates a combo of first and last name
   */
  name () {
    if (!this.populated) {
      return '';
    }
    const first_name = this.get('first_name');
    const last_name = this.get('last_name');
    return `${first_name} ${last_name}`;
  }

  clear () {
    super.clear();
    this.api.set_token(false);
  }
}

export default ND_User_Data;
