import Cookie from 'js-cookie';

export default function AutoLogin (user_obj) {
  let user_token = false;

  let timer = 500;
  const checkCookie = () => {
    const interval = 250;
    console.log('=== timer ', timer);
    const dest = /stage|local/g.test(window.location.host)
      ? 'https://aws-deploy-myaccount.newsday.com/1nd/home'
      : 'https://myaccount.newsday.com/1nd/dashboard';
    if (timer > 5000) {
      // timed out and no cookie is dropped, redirect anyway
      console.log('=== timed out, redirecting');
      window.location.href = dest;
    } else if (/igmRegID/i.test(document.cookie)) {
      console.log('=== cookie found ', Cookie.get('igmRegID'));
      setTimeout(() => {
        console.log('=== redirecting');
        window.location.href = dest;
      }, 1000);
    } else {
      console.log('=== rechecking for cookie');
      timer += interval;
      setTimeout(() => {
        checkCookie();
      }, interval);
    }
  };

  if (/usertoken=([^&]+)/.test(location.hash)) {
    const matches = location.hash.match(/usertoken=([^&]+)/);
    if (matches.length > 0) {
      user_token = matches[1];
    }

    location.hash = '';
  }

  if (/newsdayAccessTrue/.test(navigator.userAgent)) {
    let matches = navigator.userAgent.trim().match(/([^\s]+)/);

    if (/android/i.test(navigator.userAgent)) {
      matches = navigator.userAgent.trim().match(/([^\s]+)$/);
    }

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      const strings = navigator.userAgent.trim().split(' ');
      matches = [strings[strings.length - 2], strings[strings.length - 2]];
    }

    if (matches.length > 0) {
      user_token = matches[1];
    }
  }
  if (user_token) {
    Cookie.set('igmRegID', user_token, { domain: '.newsday.com' });
    user_obj.getUserData().then(() => {
      window.dispatchEvent(new Event('nd_mg2_onLoggedIn'));
    });
  }

  window.addEventListener('nd_user_data_populated', () => {
    if (/\/mmn_inapp/g.test(window.location.href)) {
      checkCookie();
    }
  });
}
