import dayjs from './dayjs';
import Config from '../config';

/**
 * Wrapper around the user object's fetch method
 */
export function getUserData () {
  return this.info.fetch().then((data) => {
    console.log('>> USER DATA >>', data);
    console.log('>> USER DATA NAME >>', Config.app.cookie.name);

    window.localStorage.setItem(
      Config.app.cookie.name,
      JSON.stringify(data.data)
    );
  });
}

/**
 * Parse the cookie content and adopt it into the user data object
 *
 * This method, after adopting the data, will evaluate the refresh_at timestamp and cookie version.
 * If the refresh_at timestamp is in the past or the cookie version doesn't equal what's expected, we get a fresh cookie
 */
export function getDataFromStorage () {
  let { user_data_storage } = this;
  user_data_storage = JSON.parse(user_data_storage);
  const data = user_data_storage;
  const { refresh_at, token } = data;
  const now = dayjs().toISOString();

  console.log('>> REFRESH AT >>', refresh_at);
  console.log('>> CURRENT TIME (REFRESH)  >>', now);
  console.log('>> USER DATA STORAGE >>', user_data_storage);
  console.log('>> COOKIE CONFIG >>', Config.app.cookie);

  if (dayjs(refresh_at).isSameOrBefore(dayjs())) {
    console.log('Refreshing stale data');
    this.getUserData()
      .then(() => {
        this.lift_off('object');
      })
      .catch((e) => {
        this.lift_off('object');
        console.log(e);
      });
  } else if (Config.app.cookie.version !== data.version) {
    console.log("Refreshing outdated storage because versions don't match");
    this.getUserData()
      .then(() => {
        this.lift_off('object');
      })
      .catch((e) => {
        this.lift_off('object');
        console.log(e);
      });
  } else {
    /**
     * If the data is good to use, adopt the values from it
     */
    this.api.set_token(token);
    this.api.set_user_endpoint(data._id);
    this.info.set(data);
    this.info.populated = true;

    this.lift_off('object');
    window.dispatchEvent(new Event('nd_user_data_populated'));
    console.log('Storage is still fresh');
  }
}
