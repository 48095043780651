import React from 'react';
import User_Component from './user_component';

class Account extends User_Component {
  state = {
    show_logout: false,
    is_logged_in: false,
    is_activated: true // assume the user IS activated so that we are revealing the activation button, instead of hiding it
  };

  componentDidMount () {
    super.componentDidMount();
    if (window.nd_user?.info?.data.email) {
      this.setState({ show_logout: true });
    } else {
      for (let counter = 0; counter < 6; counter += 1) {
        setTimeout(() => {
          if (window.nd_user?.info?.data.email) {
            this.setState({ show_logout: true });
          }
        }, 250);
      }
    }

    window.addEventListener('nd_mg2_onLoggedIn', (e) => {
      setTimeout(() => {
        this.setState({ show_logout: true });
      }, 1600);
    });

    window.addEventListener('nd_user_data_populated', (e) => {
      setTimeout(() => {
        this.setState({ show_logout: true });
      }, 1600);
    });

    window.addEventListener('nd_mg2_onLoggedOut', (e) => {
      this.setState({ show_logout: false });
    });
  }

  logged_out () {
    return (
      <div className="not-loggedin active" id="menu-not-logged-in">
        <a href="#" className="log not-loggedin logInbtn MG2login active" id="MG2login" data-mg2-action="activation" tabIndex="-1">
          <strong>LOG IN</strong>
        </a>
        <section>
          <a href={`${this.site_url()}/subscribe`} className="btn drk tBtn subacct MG2Subscribe active" id="MG2Subscribe" tabIndex="-1"><strong>SUBSCRIBE</strong></a>
          <a className="btn gBtn gray" href={`${this.site_url()}/newsletters`} tabIndex="-1">NEWSLETTERS</a>
        </section>
      </div>
    );
  }

  email () {
    const { user_obj } = this.props;
    console.log('Email');

    if (user_obj.info.populated) {
      return user_obj.info.get('email');
    }
    return '';
  }

  logout_button () {
    const { show_logout } = this.state;
    const { user_obj } = this.props;

    if (show_logout) {
      return <a className="log" href="#" onClick={(e) => { e.preventDefault(); user_obj.logout(); }}><strong>LOG OUT</strong></a>;
    }
  }

  logged_in () {
    return (
      <div className="loggedin active" id="menu-logged-in">
        {this.logout_button()}
        <section>
          <a href={`${this.site_url()}/manageaccount`} className="btn gBtn stk gray manage MMyAccount">
            <strong>MANAGE MY</strong>
            {' '}
            ACCOUNT
          </a>
          <form id="nwsltrForm" action="https://link.newsday.com/manage/55i/optout" method="post">
            <input type="hidden" name="email" value={this.email()} />
            <a href={`${this.site_url()}/newsletters`} className="btn gBtn gray newsletters stk">
              <strong>MANAGE</strong>
              {' '}
              NEWSLETTERS
            </a>
          </form>
          {this.activation_button()}
        </section>
      </div>
    );
  }

  activation_button () {
    const { is_activated } = this.state;

    if (!is_activated) {
      return (
        <a href="#" className="btn gBtn stk gray manage MG2activation" id="MG2activation" data-mg2-action="activation">
          <strong>ACTIVATE MY</strong>
          {' '}
          ACCOUNT
        </a>
      );
    }
  }

  render () {
    const { is_logged_in } = this.state;

    if (is_logged_in) {
      return this.logged_in();
    }
    return this.logged_out();
  }
}

export default Account;
