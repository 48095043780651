import ND_User_Payload from './payload';

class ND_User_Data extends ND_User_Payload {
  /**
   * Changes optin status
   */
  change (product_name, is_opted_in, save = true) {
    const product_idx = this.data.findIndex(
      (p) => p.product_name === product_name
    );

    if (product_idx === -1) {
      this.data.push({ product_name, is_opted_in });
    } else {
      Object.assign(this.data[product_idx], { is_opted_in });
    }

    if (save) {
      return this.save();
    }
  }
}

export default ND_User_Data;
