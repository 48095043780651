const config = {
  app: {
    cookie: {
      name: 'c_user',
      version: '20230316a'
    }
  }
};

export default config;
